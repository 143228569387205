import actionTypes from "../actions/actionTypes";
let _ = require("lodash");

const INITIAL_STATE = {
  logedInUserName: "",
  submittedEmail: "",
  reqStatus: "",
  roles: null,
  userId: "",
  logedInUserFirstName: "",
  loggedINUserCenterNames: [],
  loggedInUsersData: [],
  selectedCenterName: "",
  selectedCenterId: "",
  checkOnlyOneCentre: false,
  unAuthorizedLogin: false,
  initialPageLoadStatus: false,
};

const getSelectedCentreMapping = (loggedINUserCenterNames, type) =>{
  const storedSelectedCenter = loggedINUserCenterNames.find((items) => items.sourceSystems[0].orgId === localStorage.getItem("selectedCenterId"))
  if(storedSelectedCenter){
    return type ==='CENTREID' ? storedSelectedCenter.sourceSystems[0].orgId : storedSelectedCenter.name
  } else {
     return ''
    }
  }
  
const initialPageLoadData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.INITIAL_PAGE_LOAD_DATA: {
      return {
        ...state,
        logedInUserName: action.payload.response.name,
        submittedEmail: action.payload.response.email,
        roles: action.payload.loggedInUsersCentersData.length > 0 ? 'AA_NOMINATED_USER' : action.payload.response.roles[0].roleNames[0],
        userId: action.payload.response.uid,
        logedInUserFirstName: action.payload.response.given_name,
        loggedINUserCenterNames: action.payload.loggedInUsersCentersData,
        loggedInUsersData: action.payload.loggedInUsersData,
        checkOnlyOneCentre: action.payload.checkOnlyOneCentre,
        selectedCenterId: action.payload.checkOnlyOneCentre ?
                 action.payload.response.orgs[0].sourceSystems[0].orgId 
                  : getSelectedCentreMapping(action.payload.loggedInUsersCentersData, "CENTREID"),
        selectedCenterName: action.payload.checkOnlyOneCentre ?
                  action.payload.response.orgs[0].name 
                   : getSelectedCentreMapping(action.payload.loggedInUsersCentersData, "CENTRENAME"),                  
        photoUrl: _.get(action, 'payload.response.photoURL', '')
      };
    }

    case "UNAUTHORIZED_LOGIN": {
      return {
        ...state,
        unAuthorizedLogin: action.payload.unAuthorizedLogin,
      };
    }

    case "INITIAL_PAGE_LOAD_STATUS": {
      return {
        ...state,
        initialPageLoadStatus: action.payload.initialPageLoadStatus,
      };
    }

    case "SEND_CENTER_DETAILS": {
      return {
        ...state,
        selectedCenterName: action.payload.centerName,
        // Next Step in future-  uncomment the below onec parent child done and comment below condition
        selectedCenterId: action.payload.centerId,
      };
    }

    default:
      return state;
  }
};

export default initialPageLoadData;
