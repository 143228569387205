import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware } from "redux";
import thunk from "redux-thunk";

const store = configureStore(
  {
    reducer: {
      applicationState: rootReducer,
    },
  },
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
