import React, { Suspense } from "react";
import { Typography } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ErrorBoundary from "./ErrorBoundary";
import { useAuth } from "react-oidc-context";

const Home = React.lazy(() => import("./components/home"));

function App() {
  const auth = useAuth();

  //SAP C4C integration
  // auth is instance of useAuth hook (react-oidc-context)
  if (
    !auth.isAuthenticated &&
    !auth.isLoading &&
    !auth.error &&
    !auth.activeNavigator
  ) {
    auth.signinRedirect();
  }

  if (auth.isLoading) {
    return <p>Loading...</p>;
  }

  if (auth.error) {
    sessionStorage.clear();
    auth.signinRedirect();
  }

  if (auth.isAuthenticated) {
    return (
      <Suspense
        fallback={<Typography className="loading">Loading.....</Typography>}
      >
        <div className="fullWidth">
          <ErrorBoundary>
            <BrowserRouter>
              <Home />
            </BrowserRouter>
          </ErrorBoundary>
        </div>
      </Suspense>
    );
  }
}

export default App;
