import accessArrangementData from "./accessArrangementReducer";
import { combineReducers } from "redux";
import initialPageLoadData from "./initialPageLoadDataReducer";
import errorReducer from "./errorReducer";
import editCandidateDetails from "./editCandidateDetails";
import submitPayloadData from "./submitPayloadData";
import getHomePageDataReducer from "./getHomePageDataReducer";

const rootReducer = combineReducers({
  accessArrangementData: accessArrangementData,
  initialPageLoadData: initialPageLoadData,
  errorInfo: errorReducer,
  editCandidateDetails: editCandidateDetails,
  submitPayloadData: submitPayloadData,
  getHomePageDataReducer: getHomePageDataReducer,
});

export default rootReducer;
