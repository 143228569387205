import actionTypes from "../actions/actionTypes";

const iState = {
  homePageData: [],
};

const getHomePageDataReducer = (state = iState, action) => {

  if (action.type === actionTypes.GET_HOME_PAGE_DATA) {
    return {
      ...state,
      homePageData: action.payload,
    };
  } else {
    return state;
  }
};

export default getHomePageDataReducer;
