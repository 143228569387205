const actionTypes = {
  INITIAL_PAGE_LOAD_DATA: "INITIAL_PAGE_LOAD_DATA",
  INITIAL_PAGE_LOAD_STATUS: "INITIAL_PAGE_LOAD_STATUS",
  EXAM_SERIES: "EXAM_SERIES",
  UPDATE_REQID: "UPDATE_REQID",
  OTHER_CENTERS : "OTHER_CENTERS",
  ALL_SYLLABUS: "ALL_SYLLABUS",
  GET_CHILD_APPLICATIONS: "GET_CHILD_APPLICATIONS",
  UPDATE_REQUEST_PAYLOAD: "UPDATE_REQUEST_PAYLOAD",
  PARENT_SUBMIT_TO_CHILD: 'PARENT_SUBMIT_TO_CHILD',
  ROUTE_VIA_MULTIPLE_CAND: "ROUTE_VIA_MULTIPLE_CAND",
  ADD_CANDIDATE_BUTTON_CLICKED: "ADD_CANDIDATE_BUTTON_CLICKED",
  AA_SUBMIT_API_REQUEST: "AA_SUBMIT_API_REQUEST",
  AA_SUBMIT_API_RESPONSE: "AA_SUBMIT_API_RESPONSE",
  RESET_CANDIDATE_DETAILS: "RESET_CANDIDATE_DETAILS",
  AA_SUBMIT_API_ERROR: "AA_SUBMIT_API_ERROR",
  GET_API_STATUS_CHECK: "GET_API_STATUS_CHECK",
  SUBMIT_API_STATUS_CHECK: "SUBMIT_API_STATUS_CHECK",
  QUALIFICATION_LEVEL: "QUALIFICATION_LEVEL",
  UPDATE_ARRANGEMENT_LIST: "UPDATE_ARRANGEMENT_LIST",
  REORDER_THE_ARRANGEMENT: "REORDER_THE_ARRANGEMENT",
  RESET_STORE: "RESET_STORE",
  BUILD_SUBMIT_PAYLOAD: "BUILD_SUBMIT_PAYLOAD",
  UPDATE_PAYLOAD: "UPDATE_PAYLOAD",
  LOADING: "LOADING",
  ADD_DIFFERENT_SYLLABUS_SCRIBE: "ADD_DIFFERENT_SYLLABUS_SCRIBE",
  LOAD_HOME_PAGE: "LOAD_HOME_PAGE",
  TOTAL_ACTIVE_SUBJECTS: "TOTAL_ACTIVE_SUBJECTS",
  LOADING_CANDIDATE_DETAILS: "LOADING_CANDIDATE_DETAILS",
  UPDATE_EXISTING_ARRANGEMENT_LIST: "UPDATE_EXISTING_ARRANGEMENT_LIST",
  AMMEND_APPLICATION: "AMMEND_APPLICATION",
  CHANGE_ISACTIVE_FLAG: "CHANGE_ISACTIVE_FLAG",
  ADD_DIFFERENT_SYLLABUS_EXTRA_TIME: "ADD_DIFFERENT_SYLLABUS_EXTRA_TIME",
  UPDATE_ACTIVE_FLAG: "UPDATE_ACTIVE_FLAG",
  RESET_STATUS: "RESET_STATUS",
  ERROR_SYLLABUS_CODE: "ERROR_SYLLABUS_CODE",
  ERROR_COMPONENT_CODE: "ERROR_COMPONENT_CODE",
  ERROR_OPTIONS: "ERROR_OPTIONS",
  ERROR_UPLOAD_CODE: "ERROR_UPLOAD_CODE",
  UPDATE_STATUS: "UPDATE_STATUS",
  NOTIFICATION_ERROR: "NOTIFICATION_ERROR",
  ERROR_SUPPLEMENTARY: "ERROR_SUPPLEMENTARY",
  SAVE_CANDIDATE_INITIAL_DETAILS: "SAVE_CANDIDATE_INITIAL_DETAILS",
  BACK_UP_PAYLOAD: "BACK_UP_PAYLOAD",
  UPDATE_STORE: "UPDATE_STORE",
  DELETE_CANDIDATE: "DELETE_CANDIDATE",
  ADD_NEW_CANDIDATE: "ADD_NEW_CANDIDATE",
  EDIT_CANDIDATE_DETAILS: "EDIT_CANDIDATE_DETAILS",
  STUDENT_LIST: "STUDENT_LIST",
  SET_BTN_DISABLED: "SET_BTN_DISABLED",
  RESET_NOTIFICATION_ERROR: "RESET_NOTIFICATION_ERROR",
  ERROR_MEDICALDEVICE: "ERROR_MEDICALDEVICE",
  UPDATE_SYLLABUS_AND_COMPONENT_CODE: "UPDATE_SYLLABUS_AND_COMPONENT_CODE",
  ADD_DIFFERENT_SYLLABUS: "ADD_DIFFERENT_SYLLABUS",
  SAVE_CANDIDATE_BTN_CLICKED: "SAVE_CANDIDATE_BTN_CLICKED",
  DELETE_THE_SYLLABUS: "DELETE_THE_SYLLABUS",
  REMOVE_EMPTY_VALUE_FROM_COMPONENT_CODES:
    "REMOVE_EMPTY_VALUE_FROM_COMPONENT_CODES",
  CANDIDATE_NUMBER: "CANDIDATE_NUMBER",
  CANDIDATE_NUMBER_ERROR: "CANDIDATE_NUMBER_ERROR",
  GET_CALL_RESPONSE: "GET_CALL_RESPONSE",
  SET_UPDATED_STATUS_FLAG: "SET_UPDATED_STATUS_FLAG",
  CHANGE_SUPPLEMENTARY_ITEMS: "CHANGE_SUPPLEMENTARY_ITEMS",
  RESET_SUPPLEMENTARY_ITEMS: "RESET_SUPPLEMENTARY_ITEMS",
  SAVE_OPTION_ARRAY: "SAVE_OPTION_ARRAY",
  SAVE_OPTION_PARAM: "SAVE_OPTION_PARAM",
  SEND_REQUEST_NUMBER: "SEND_REQUEST_NUMBER",
  SEND_MESSAGE: "SEND_MESSAGE",
  GET_BACK_TO_HOME_PAGE: "GET_BACK_TO_HOME_PAGE",
  GET_HOME_PAGE_DATA: "GET_HOME_PAGE_DATA",
  GET_OUTCOME_RESULTS: "GET_OUTCOME_RESULTS",
  ADD_NEW_CANDIDATE_UUID: "ADD_NEW_CANDIDATE_UUID",
  UPDATE_FILE_UPLOAD: "UPDATE_FILE_UPLOAD",
  UPDATE_FILE_UPLOAD_STATUS: "UPDATE_FILE_UPLOAD_STATUS",
  UPDATE_FILE_EVIDENCE_STATUS: "UPDATE_FILE_EVIDENCE_STATUS",
  ALL_UPLOADED_FILES_STATUS: "ALL_UPLOADED_FILES_STATUS",
  EVIDENCE_REQUIRED_CANDIDATES: "EVIDENCE_REQUIRED_CANDIDATES",
  EDIT_CANDIDATE_UPLOADED_FILES_STATUS: "EDIT_CANDIDATE_UPLOADED_FILES_STATUS",
  ENABLE_BTN_ALLEVIDENCE_UPLOADED: "ENABLE_BTN_ALLEVIDENCE_UPLOADED",
  DELETE_EVIDENCE_FILE: "DELETE_EVIDENCE_FILE",
  CHANGE_UPLOADED_EVIDENCE_FLAG: "CHANGE_UPLOADED_EVIDENCE_FLAG",
  GET_CENTRE_COUNTRY_C4C_DATA: "GET_CENTRE_COUNTRY_C4C_DATA",
  RESET_ALL_UPLOADED_FILES_STATUS: "RESET_ALL_UPLOADED_FILES_STATUS",
  CHECKIS_FILEUPLOAD_STUCK_ERROR: "CHECKIS_FILEUPLOAD_STUCK_ERROR",
  LOAD_CONFIRMATION_PAGE:"LOAD_CONFIRMATION_PAGE",
  UPDATE_US_EVIDENCE_DETAILS:"UPDATE_US_EVIDENCE_DETAILS",
  UPDATE_EVIDENCE_UPLOADED_STATUS:"UPDATE_EVIDENCE_UPLOADED_STATUS",
  LOAD_COPYPREVSERIES_PAGEDATA:"LOAD_COPYPREVSERIES_PAGEDATA",
  EVIDENCE_ERROR_UPLOAD_TEXT: "EVIDENCE_ERROR_UPLOAD_TEXT",
  GET_PARENTCENTRE_OF_CHILD_DATA:"GET_PARENTCENTRE_OF_CHILD_DATA",
  LOAD_PARENTCHILD_HOMEPAGE_DATA:"LOAD_PARENTCHILD_HOMEPAGE_DATA",
  GET_CHILD_OUTCOME_RESULT:"GET_CHILD_OUTCOME_RESULT",
  RESET_PARENTCENTRE:"RESET_PARENTCENTRE",
  UNAUTHORIZED_LOGIN: "UNAUTHORIZED_LOGIN",
  RESET_OUTCOME_RESULTS:"RESET_OUTCOME_RESULTS"
};

export default actionTypes;
