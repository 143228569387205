import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/index.js";
import { mainTheme } from "@cambridgeassessment/cambridge-ui";
import {
  ThemeProvider,
  CssBaseline,
  StyledEngineProvider,
} from "@mui/material";
import { AuthProvider } from "react-oidc-context";
/**
 *
 * Please do not remove the commented code as LOGIN is in progress
 */
const root = ReactDOM.createRoot(document.getElementById("root"));

const onSigninCallbackFunc= () => {
  window.location.href = "/";
}

const oidcConfig = {
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL, //Note: for localhost change it to https://localhost:3000
  scope: "openid profile email photoURL roles orgs businessStream uid",
  response_type: "code",
  automaticSilentRenew: true,
  onSigninCallback: onSigninCallbackFunc,
};
root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <AuthProvider {...oidcConfig} >
          <CssBaseline />
          <App />
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
