import actionTypes from "../actions/actionTypes";
import content from "../util/content";

const INITIAL_STATE = {
  evidenceFile:{
    evidenceNotUploadedError:"",
    textErrorOne: "",
    textErrorTwo: "",
    textErrorThree: "",
  },
  extraTime: {
    optionError: "",
    syllabusError: "",
    componentCodeError: "",
  },
  scribe: {
    optionError: "",
    syllabusError: "",
    componentCodeError: "",
  },
  computerReader: {
    syllabusError: "",
    componentCodeError: "",
  },
  humanReader: {
    syllabusError: "",
    componentCodeError: "",
  },
  exemptionRequest: {
    optionError: "",
    syllabusError: "",
    componentCodeError: "",
  },
  voiceActivated: {
    syllabusError: "",
    componentCodeError: "",
  },
  wordProcessor: {
    optionError: "",
  },
  supplementaryAids: {
    supplError: "",
  },
  medicalDevices: {
    textError: "",
  },
  genericErrorList: [],
};

const errorList = (stateValues) => {
  let variableName = content.arrangementsListVariables;
  let filteredList = variableName.filter((v) => {
    let obj = stateValues[v];
    let objValues = Object.values(obj);
    if (objValues.includes("error")) {
      return v;
    }
    return "";
  });
  return filteredList;
};

const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ERROR_SYLLABUS_CODE: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          syllabusError: action.payload.error,
        },
      };
    }
    case actionTypes.ERROR_COMPONENT_CODE: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          componentCodeError: action.payload.error,
        },
      };
    }
    case actionTypes.ERROR_OPTIONS: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          optionError: action.payload.error,
        },
      };
    }
    case actionTypes.ERROR_SUPPLEMENTARY: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          supplError: action.payload.error,
        },
      };
    }
    case actionTypes.ERROR_MEDICALDEVICE: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          textError: action.payload.error,
        },
      };
    }
    
    case actionTypes.ERROR_UPLOAD_CODE: {
      return {
        ...state,
        [action.payload.variable]: {
          ...state[action.payload.variable],
          evidenceNotUploadedError: action.payload.error,
        },
      };
    }
    case actionTypes.NOTIFICATION_ERROR: {
      return {
        ...state,
        genericErrorList: errorList(state),
      };
    }
    case actionTypes.RESET_NOTIFICATION_ERROR: {
      const resetState = {
        extraTime: {
          optionError: "",
          syllabusError: "",
          componentCodeError: "",
        },
        scribe: {
          optionError: "",
          syllabusError: "",
          componentCodeError: "",
        },
        computerReader: {
          syllabusError: "",
          componentCodeError: "",
        },
        humanReader: {
          syllabusError: "",
          componentCodeError: "",
        },
        exemptionRequest: {
          optionError: "",
          syllabusError: "",
          componentCodeError: "",
        },
        voiceActivated: {
          syllabusError: "",
          componentCodeError: "",
        },
        wordProcessor: {
          optionError: "",
        },
        supplementaryAids: {
          supplError: "",
        },
        medicalDevices: {
          textError: "",
        },
        evidenceFile:{
          evidenceNotUploadedError:"",
          textErrorOne: "",
          textErrorTwo: "",
          textErrorThree: "",
        },
        genericErrorList: [],
      };
      return resetState;
    }
    default:
      return state;
  }
};

export default errorReducer;
