import { v4 as uuidv4 } from "uuid";
const content = {
  hearderText: "Apply for Access Arrangements",
  headerSubText: "Access Arrangements Application",
  helperTextOne:
    "Our New Portal allows you to apply for special need requirements for your students.",
  helperTextTwo:
    "From June 2023 you will be able to apply and review all Access Arrangements for your center.",
  startNewApplicationButtonText: "Start New Application",
  mobileErrorHelperText: "Not Available on Small Screens",
  mobileErrorHelperSubText:
    "Please use a Laptop or Large Screen to apply for Access arrangements",
  candidateSummaryZeroCandidateContent: "There are no candidates for this exam series. Add candidates by clicking on the button above.",
  arrangementsList: {
    candidateID: "", // The id of the candidate in the Appian DB (This will be null if a new candidate is added)
    updatedStatus: "", // status can be [''|'Added'|'Deleted'|'Edited'] - Flag in Appian IsActive
    candidateNumber: "", // Number entered by the centre for this candidate
    firstName: "", // First name entered by the centre
    lastName: "", // Last name entered by the centre
    qualificationLevel: "", // Qualification selected by the centre
    status: null, // Status of the candidate in Appian (The front end doesn't change this)
    isActive: true,
    candidateUUID: uuidv4(),
    uploadedEvidence: false,
    evidenceDetails: [],
  },
  qualifications: ["IGCSE", "AS/A Level", "O Level", "Pre-U", "Checkpoint"],
  usEvidenceTypeList: ['504 Plan', 'IEP', 'Other'],
  usEvidencePrevList: ['Yes', 'No', 'Partial'],
  supplementaryAidsList: [
    { label: "Coloured overlays", id: "colouredOverlays", isChecked: false },
    { label: "Ear defenders", id: "earDefenders", isChecked: false },
    { label: "Ear plugs", id: "earPlugs", isChecked: false },
    { label: "Fidget toys", id: "fidgetToys", isChecked: false },
    { label: "Headphones", id: "headPhones", isChecked: false },
    { label: "Raised desk", id: "raisedDesk", isChecked: false },
    { label: "Reading ruler", id: "readingRuler", isChecked: false },
    { label: "Sunglasses", id: "sunglasses", isChecked: false },
    { label: "Sit/stand desk", id: "sitStandDesk", isChecked: false },
    { label: "Tinted glasses", id: "tintedGlasses", isChecked: false },
    { label: "Tinted ruler", id: "tintedRuler", isChecked: false },
    { label: "Tactile ruler", id: "tactileRuler", isChecked: false },
    {
      label: "Visual aids e.g. magnifying glass",
      id: "visualAids",
      isChecked: false,
    },
    { label: "Writing slope", id: "writingSlope", isChecked: false },
  ],
  wordProcessorDropDownList: [
    "With spell check",
    "Without spell check",
    "Both",
  ],
  scribeDropDownList: [
    "Non-language syllabuses",
    "Language syllabuses - candidate dictates spelling & punctuation",
    "Language syllabuses - candidate does not dictate spelling",
  ],
  arrangementsListVariables: [
    "evidenceFile",
    "extraTime",
    "computerReader",
    "exemptionRequest",
    "voiceActivated",
    "scribe",
    "wordProcessor",
    "humanReader",
    "supplementaryAids",
    "medicalDevices",
  ],
  variableMapping:
  {
    'Candidate number': 'candidateNumber',
    'CandidateNumber': 'candidateNumber',
    'candidatenumber': 'candidateNumber',
    'CANDIDATE NUMBER': 'candidateNumber',
    'candidate number': 'candidateNumber',
    'CANDIDATENUMBER': 'candidateNumber',
    'Candidate Number': 'candidateNumber',
    'First name': 'firstName',
    'first name': 'firstName',
    'FIRSTNAME': 'firstName',
    'firstname': 'firstName',
    'First Name': 'firstName',
    'FIRST NAME': 'firstName',
    'Last name': 'lastName',
    'Firstname': 'firstName',
    'last Name': 'lastName',
    'lastname': 'lastName',
    'LASTNAME': 'lastName',
    'last name': 'lastName',
    'LAST NAME': 'lastName',
    'Family name': 'lastName',
    'familyname': 'lastName',
    'Familyname': 'lastName',
    'FAMILYNAME': 'lastName'
  },
  replacement: [
    "Evidence required",
    "Extra time",
    "Computer reader",
    "Exemption",
    "Voice activated software",
    "Scribe",
    "Word processor",
    "Human reader",
    "Supplementary aids",
    "Medical devices",
  ],
  fullList: [
    "extraTime",
    "computerReader",
    "humanReader",
    "exemptionRequest",
    "scribe",
    "voiceActivated",
    "supplementaryAids",
    "practicalAssistant",
    "colourNaming",
    "medicalDevices",
    "prompter",
    "readingAloud",
    "readingPens",
    "supervisedBreaks",
    "transcript",
    "wordProcessor",
  ],
  lowRiskArrangementList: [
    "practicalAssistant",
    "colourNaming",
    "prompter",
    "readingAloud",
    "readingPens",
    "supervisedBreaks",
    "transcript",
  ],

  replacementList: [
    "Extra time",
    "Computer reader",
    "Human reader",
    "Exemption",
    "Scribe",
    "Voice activated software",
    "Supplementary aids",
    "Practical assistant",
    "Colour naming",
    "Medical devices",
    "Prompter",
    "Reading aloud (candidate)",
    "Reading pens",
    "Supervised rest breaks",
    "Transcript of candidate’s work",
    "Word processor",
  ],
  userRoles: [
    { AA_NOMINATED_USER: "AA nominated user" },
    { DELEGATED_ADMIN: "Delegated admin" },
    { EXAMS_OFFICER_: "Exams officer" },
  ],
  amendNotification: {
    plainText: "Once you have made your changes to the application",
    boldText:
      "you must click the 'Submit to Cambridge' button or your changes will be lost.",
  },
  confirmationText: {
    leaveNotification:
      "You are about to leave this application and your changes will be lost. Do you want to continue?",
  },
  fileUploadMsgObj: {
    uploadRequiredMsg: "You must upload evidence of need for this candidate",
    uploadFaliedMsg:
      "The upload has failed. The file type is not allowed and / or the file size is greater that 100MB.",
    uploadFileAllowedType:
      "Files allowed: .doc, .docx, .pdf, .txt, .png , .gif, .jpg, .jpeg",
  },
  amendApplicationMsg: {
    amendTitleTxt: "Make changes to this application",
    makeChangesTxt:
      "You are about to make changes to your existing application.",
    uploadInstructions:
      "You can upload more evidence, select different access arrangements, or add / remove candidates.",
    afterSubmitionTxt:
      "Once you submit the application to us, we will review it and send you a revised outcome.",
  },
  extraTimeMsg: {
    extraTimeErrTxt: "Enter a percentage - this must be a whole number e.g. 25",
    extraTimeCompErr: "Please enter a valid 2 digit component number",
  },
  candidateInfoMsg: {
    candidateNumbersError:
      "Enter a valid candidate number. Candidate numbers must only contain numbers",
    candidateDeleteNotification:
      "You are about to delete this candidate. Are you sure?",
  },
  aaArrangementList: {
    colourNaming: {
      flag: false,
    },
    computerReader: {
      flag: false,
    },
    exemptionRequest: {
      flag: false,
    },
    extraTime: {
      flag: false,
    },
    humanReader: {
      flag: false,
    },
    medicalDevices: {
      flag: false,
    },
    practicalAssistant: {
      flag: false,
    },
    prompter: {
      flag: false,
    },
    readingAloud: {
      flag: false,
    },
    readingPens: {
      flag: false,
    },
    scribe: {
      flag: false,
    },
    supervisedBreaks: {
      flag: false,
    },
    supplementaryAids: {
      flag: false,
    },
    transcript: {
      flag: false,
    },
    voiceActivated: {
      flag: false,
    },
    wordProcessor: {
      flag: false,
    },
  },

  aaArrangmentObjectList: [
    { label: "Extra time", value: "extraTime" },
    { label: "Computer reader", value: "computerReader" },
    { label: "Human reader", value: "humanReader" },
    { label: "Exemption", value: "exemptionRequest" },
    { label: "Scribe", value: "scribe" },
    { label: "Voice activated software", value: "voiceActivated" },
    { label: "Supplementary aids", value: "supplementaryAids" },
    { label: "Practical assistant", value: "practicalAssistant" },
    { label: "Medical devices", value: "medicalDevices" },
    { label: "Colour naming", value: "colourNaming" },
    { label: "Prompter", value: "prompter" },
    { label: "Reading aloud (candidate)", value: "readingAloud" },
    { label: "Reading pens", value: "readingPens" },
    { label: "Supervised rest breaks", value: "supervisedBreaks" },
    { label: "Transcript of candidate’s work", value: "transcript" },
    { label: "Word processor", value: "wordProcessor" },
  ],
};

export default content;
